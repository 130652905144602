// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Navbar from './components/NavBar';  // Navbar Component
import ResponsiveCards from './components/Cards';  // Home page with cards
import ContactUs from './components/ContactUs';  // Contact Us page
import Footer from './components/Footer'; // Footer Component

const App = () => {
  // Create a global theme with font
const theme = createTheme({
  typography: {
    fontFamily: '"Playfair Display", serif',  // Choose your font here
  },
  palette: {
    primary: {
      main: '#006400',  // Royal green
    },
  },
});

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <Router>
      <Navbar /> {/* Navigation bar remains fixed at the top */}
      
      {/* Main content area with margin to prevent overlap with the navbar */}
      <div style={{ marginTop: '64px', marginBottom: '120px' }}> {/* Ensures content is below the navbar and above the footer */}
        <Routes>
          <Route path="/" element={<ResponsiveCards />} /> {/* Home page with cards */}
          <Route path="/contact" element={<ContactUs />} /> {/* Contact Us page */}
        </Routes>
      </div>

      {/* Footer */}
      <Footer />
    </Router>
    </ThemeProvider>
  );
};

export default App;
