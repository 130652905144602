import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Button, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import jeera from './images/Jeera.png'
import mustard from './images/mustard.png'
import methi from './images/methi.png'
import dhaniya from './images/dhaniya.png'
import poppySeeds from './images/poppyseeds.png'
import dalchini from './images/dalchini.png'
import elaichi from './images/elaichi.png'
import lavang from './images/lavang.png'
import badiana from './images/badiana.png'
import blackPepper from './images/blackpepper.png'
import magaj from './images/magaj.png'
const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15), 0px 6px 20px rgba(0, 0, 0, 0.1)', // Floating shadow effect
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out', // Add transition for hover effect
  '&:hover': {
    transform: 'scale(1.05)', // Slight scale effect on hover
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.25), 0px 20px 40px rgba(0, 0, 0, 0.2)', // Increased shadow on hover
  },
});

const StyledGrid = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap', // Ensure the items wrap
  justifyContent: 'center', // Center the cards horizontally
  padding: '20px',
});

const CardImage = styled(CardMedia)({
  height: 200,
  objectFit: 'cover',
});

const DescriptionText = styled(Typography)({
  fontSize: '0.875rem',
  color: '#555',
  lineHeight: '1.4',
  paddingBottom: '10px',
  transition: 'height 0.3s ease', // Smooth transition for height
  height: '60px', // Truncated height to show the short description
  overflow: 'hidden',
});

const LearnMoreButton = styled(Button)({
  marginTop: '10px',
  color: '#00796b', // Custom color
  textTransform: 'none',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: '#004d40',
    color: '#ffffff',
  },
});

const cardsData = [
  {
    image: jeera,
    title: 'Jeera',
    description: 'Jeera, also known as cumin, is the dried seed of the herb Cuminum cyminum, which belongs to the parsley family (Apiaceae). It is widely used in various cuisines, particularly in Indian cooking, where it adds a distinctive warm and slightly bitter flavor to dishes. Jeera is available in different forms, including whole seeds, ground powder, and roasted varieties.',
  },
  {
    image: mustard,
    title: 'Mustard',
    description: 'Mustard is a condiment made from the seeds of the mustard plant, which belongs to the cruciferous family. There are several key varieties of mustard, each with unique characteristics and culinary uses.',
  },
  {
    image: methi,
    title: 'Methi',
    description: 'Methi seeds, commonly known as fenugreek seeds, are the dried seeds of the Trigonella foenum-graecum plant. These small, golden-brown seeds have a distinctive flavor that is slightly bitter and nutty, making them a popular ingredient in various cuisines, especially in Indian and Middle Eastern cooking.',
  },
  {
    image: dhaniya,
    title: 'Dhaniya',
    description: 'Dhaniya seeds, commonly known as coriander seeds, are the dried seeds of the Coriandrum sativum plant. They are a staple spice in various cuisines around the world, particularly in Indian, Middle Eastern, and Mediterranean cooking.',
  },
  {
    image: poppySeeds,
    title: 'Poppy Seeds',
    description: 'Poppy seeds, known scientifically as Papaver somniferum, are small, oval-shaped seeds harvested from the opium poppy plant. These seeds are widely used in various culinary applications due to their pleasant nutty flavor and crunchy texture.',
  },
  {
    image: dalchini,
    title: 'Dal Chini (Cinnamon)',
    description: 'Dal Chini, also known as cinnamon, refers to the inner bark of trees belonging to the genus Cinnamomum. It is a popular spice used in various cuisines around the world, particularly in Indian, Middle Eastern, and Western cooking.',
  },
  {
    image: elaichi,
    title: 'Elaichi',
    description: 'Elaichi, commonly known as cardamom, is a highly aromatic spice derived from the seeds of plants in the Zingiberaceae family, particularly from the species Elettaria cardamomum (green cardamom) and Amomum subulatum (black cardamom). It is often referred to as the "Queen of Spices" due to its unique flavor and extensive use in culinary applications.',
  },
  {
    image: lavang,
    title: 'Lawang (Clove)',
    description: 'Lawang, commonly referred to as clove, is the dried flower bud of the Syzygium aromaticum tree. It is a popular spice known for its strong, aromatic flavor and is widely used in various cuisines around the world.',
  },
  {
    image: badiana,
    title: 'Badiana (Star Flower)',
    description: 'Badiana, commonly known as star anise, refers to the spice derived from the fruit of the Illicium verum tree. This spice is notable for its star-shaped appearance and strong flavor, reminiscent of anise due to its high anethole content.',
  },
  {
    image: blackPepper,
    title: 'Black Pepper',
    description: 'Black pepper, known scientifically as Piper nigrum, is a flowering vine native to South India. It is widely regarded as the "king of spices" due to its extensive use and popularity in cuisines around the world.',
  },
  {
    image: magaj,
    title: 'Magaj',
    description: 'Magaj, often referred to in culinary contexts, specifically denotes melon seeds (particularly from the watermelon or muskmelon). These seeds are commonly used in various cuisines, especially in Indian cooking, where they are valued for their nutritional benefits and culinary versatility.',
  },
];

const ResponsiveCards = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggleDescription = (index) => {
    setExpandedIndex((prev) => (prev === index ? null : index)); // Toggle expansion for each card
  };

  return (
    <StyledGrid container spacing={4}>
      {cardsData.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <StyledCard>
            <CardImage image={card.image} title={card.title} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <Typography variant="h6" align="center" style={{ marginBottom: '10px' }}>
                {card.title}
              </Typography>
              {/* Short description */}
              <DescriptionText style={{ height: expandedIndex === index ? 'auto' : '60px' }}>
                {card.description}
              </DescriptionText>
              {/* Full description shown when expanded */}
              <LearnMoreButton onClick={() => handleToggleDescription(index)} fullWidth>
                {expandedIndex === index ? 'Show Less' : 'Learn More'}
              </LearnMoreButton>
            </CardContent>
          </StyledCard>
        </Grid>
      ))}
    </StyledGrid>
  );
};

export default ResponsiveCards;