// ContactUs.js
import React from 'react';
import { Container, Typography, Box, Card, CardContent, IconButton } from '@mui/material';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PhoneIcon from '@mui/icons-material/Phone';
import Navbar from './NavBar';  // Import Navbar
import Footer from './Footer';  // Import Footer

const ContactUs = () => {
  return (
    <div>
      {/* Navbar Section */}
      <Navbar />

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ paddingTop: '100px' }}>
        {/* Contact Us Section */}
        <Box sx={{ textAlign: 'center', marginBottom: '30px' }}>
          <Typography variant="h4" sx={{ marginBottom: '10px', color: '#006400', fontWeight: 'bold' }}>
            Contact Us
          </Typography>
        </Box>

        {/* Responsive Card with Address and Phone Info */}
        <Card sx={{
          maxWidth: 400, 
          margin: '0 auto', 
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', 
          borderRadius: '8px', 
          padding: '20px',
          marginBottom: '40px',
          backgroundColor: '#fff',
          transition: 'box-shadow 0.3s ease',
          '&:hover': {
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
          }
        }}>
          <CardContent>
            {/* Address Section */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <IconButton sx={{ color: '#006400', marginRight: '5px' }}>
                <PinDropIcon />
              </IconButton>
              <Typography variant="body1" sx={{ color: '#333', fontSize: '1rem' }} textAlign={'center'}>
                Annamayya Street, 
                Back side Ganapati Temple, 
                Near S.V.G. Market, opp.RTC Complex, 
                Rajamahendravaram
              </Typography>
            </Box>

            {/* Phone Section */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton sx={{ color: '#006400', marginRight: '10px' }}>
                <PhoneIcon />
              </IconButton>
              <Typography variant="body1" sx={{ color: '#333', fontSize: '1rem' }} textAlign={'center'}>
                Gunavanth Kumar: +91 9848054810<br/>
                Akshat Kumar: +91 9000386326
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default ContactUs;
