import React from 'react';
import { Box, Typography, Link, Grid2 as Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(45deg, #006400, #4CAF50, #FFD700)', // Multi-color gradient for footer
        color: '#FFFFF0', // Ivory text color
        padding: '20px 0', // Padding for the footer
        width: '100%',
        boxShadow: '0 -4px 10px rgba(0, 0, 0, 0.2)', // Box shadow on top of the footer
        zIndex: 1000,
        marginTop: '20px', // Ensure the footer is not overlapping
      }}
    >
      <Box sx={{ paddingX: { xs: '16px', sm: '40px', md: '80px' } }}>
        <Grid container spacing={3} justifyContent={'space-between'}>
          {/* Left side - Useful Links section */}
          <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',textAlign:'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
              Useful Links
            </Typography>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Link
                component={RouterLink}
                to="/"
                sx={{
                  color: '#FFFFF0',
                  textDecoration: 'none',
                  fontSize: '1rem', // Reduced font size
                  fontWeight: 'bold',
                }}
              >
                Home
              </Link>
              <Link
                component={RouterLink}
                to="/contact"
                sx={{
                  color: '#FFFFF0',
                  textDecoration: 'none',
                  fontSize: '1rem', // Reduced font size
                  fontWeight: 'bold',
                }}
              >
                Contact Us
              </Link>
            </Box>
          </Grid>

          {/* Right side - Address and Contact Number */}
          <Grid item xs={12} sm={6} sx={{ textAlign: 'center', paddingLeft: { sm: '16px' }, paddingRight: { sm: '16px' } }}>
            <Typography variant="body2" sx={{ fontSize: '1.1rem', fontWeight: 'bold', marginBottom: '8px' }}>
              <strong>Our Address:</strong>
            </Typography>
            <Typography variant='body2' sx={{ fontSize: '1rem', marginBottom: '12px' }}>
              Annamayya Street, <br />
              Back side Ganapati Temple, <br />
              Near S.V.G. Market, opp.RTC Complex, <br />
              Rajamahendravaram
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '1.1rem', fontWeight: 'bold', marginBottom: '8px' }}>
              <strong>Mobile Number:</strong>
            </Typography>
            <Typography variant='body2' sx={{ fontSize: '1rem', marginBottom: '5px' }}>
              Gunavanth Kumar: +91 9848054810
            </Typography>
            <Typography variant='body2' sx={{ fontSize: '1rem' }}>
              Akshat Kumar: +91 9000386326
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;