// Navbar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

// Royal Green Navbar Styling with Multi-color Gradient
const StyledAppBar = styled(AppBar)({
  background: 'linear-gradient(45deg, #006400, #4CAF50, #FFD700)', // Multi-color gradient
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', // Light shadow for a subtle floating effect
  borderBottom: '4px solid rgba(0, 0, 0, 0.3)', // Box shadow on bottom
});

const Logo = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  color: '#FFFFF0', // Ivory color for logo text
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  padding: '10px',
  backgroundColor: 'transparent',
  textShadow: '2px 2px 5px rgba(255, 215, 0, 0.5)', // Shiny text shadow effect
});

const NavbarButton = styled(Button)({
  color: '#FFFFF0', // Ivory text color
  textTransform: 'none',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#8B4513', // Dark brown color on hover for better contrast
    boxShadow: '0 4px 15px rgba(255, 215, 0, 0.3)', // Subtle glow effect
  },
});

// Center Title Styling with Logo Font (e.g., Dancing Script, Poppins)
const Title = styled(Typography)({
  flex: 1, // This ensures the title takes up remaining space and is centered
  textAlign: 'center',
  fontSize: '2.5rem', // Default large size for the title
  fontWeight: 'bold',
  color: '#FFFFF0', // Ivory color for title
  fontFamily: "'Dancing Script', cursive", // Logo-like font
  textTransform: 'uppercase',
  '@media (max-width: 600px)': { // Make title smaller on smaller screens
    fontSize: '1.8rem',
  },
  '@media (max-width: 400px)': { // Further reduce font size on very small screens
    fontSize: '1.5rem',
  },
});

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen); // Toggle the drawer menu on small screens
  };

  return (
    <StyledAppBar position="fixed">
      <Toolbar sx={{ justifyContent: 'space-between', display: 'flex' }}>
        {/* Logo Section */}
        <Logo variant="h6">BS</Logo>

        {/* Centered Title */}
        <Title variant="h6">Bhagya Lakshmi Spices</Title>

        {/* Desktop Navbar Links */}
        {!isMobile ? (
          <div>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <NavbarButton>Home</NavbarButton>
            </Link>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
              <NavbarButton>Contact Us</NavbarButton>
            </Link>
          </div>
        ) : (
          // Mobile (hamburger) menu
          <div>
            <IconButton color="inherit" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          </div>
        )}

        {/* Drawer for Small Screens */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: '#006400', // Royal Green for the drawer background
              width: '200px', // Set a smaller width for the drawer to look compact
            },
          }}
        >
          <List sx={{ width: '100%' }}>
            <ListItem button onClick={toggleDrawer} sx={{ '&:hover': { backgroundColor: '#8B4513' } }}>
              <Link to="/" style={{ textDecoration: 'none', color: '#FFFFF0' }}>
                <ListItemText primary="Home" />
              </Link>
            </ListItem>
            <ListItem button onClick={toggleDrawer} sx={{ '&:hover': { backgroundColor: '#8B4513' } }}>
              <Link to="/contact" style={{ textDecoration: 'none', color: '#FFFFF0' }}>
                <ListItemText primary="Contact Us" />
              </Link>
            </ListItem>
          </List>
        </Drawer>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;